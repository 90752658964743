.primary_button_con {
  background-color: #0e1073;
  border-radius: 6px;
  border: none;
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  padding: 8px 24px;
}
.BackGroudLight {
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 6px;
  color: #0e1073;
  background-color: #d4dffe;
  border: none;
  font-weight: 600 !important;
  font-size: 15px;
}
.delete_button{
    background: transparent;
    border: none;
    color: #6E7079;
    font-size: 15px;
    font-weight: 600 !important;
}
