* {
  margin: 0;
  /* padding: 0; */
  box-sizing: border-box;
  /* border-radius: 10px; */
  /* padding: 10px 8px; */
}

body {
  /* font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
  /* display: flex;
    justify-content: center; */
  /* align-items: center; */
}
.login {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 100vh;
  width: 100%;
  background: #5355a5;
  padding: 10px 8px;
}

.wrapper {
  width: 560px;
  background: #ffffff;
  color: black;
  border-radius: 20px;
  padding: 0px 20px 60px;
}
.wrapper h2 {
  font-size: 20px;
  text-align: center;
}
.wrapper h3 {
  font-size: 15px;
  text-align: center;
  padding: 2px;
}

.logooo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 45%;
}

.logotest {
  text-align: center;
}

.wrapper .input-box {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0px 0;
}

.wrapper .input-box ::placeholder {
  color: #574d4dd5;
  font-size: 15px;
}

.wrapper_1 {
  padding: 40px 0px;
}

.wrapper_2 {
  padding: 8px 20px;
  color: #202224;
  font-family: Avenir Next LT Pro;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 21.6px;
  letter-spacing: -0.06428570300340652px;
  opacity: 0.8;
}

.d-flex-class {
  display: flex;
  justify-content: space-between;
}
.input-box input {
  width: 100%;
  height: 100%;
  background: #F1F4F9;
  outline: none;
  border: 1px solid #D8D8D8;
  border-radius: 15px;
  font-size: 16px;
  color: #000000;
  padding: 20px 45px 20px 20px;
}

.wrapper .input-box .fname {
  color: #414040;
  font-size: 15px;
}

.input-box input::placeholder {
  color: #A6A6A6 !important ;
  font-size: 18px;
  font-weight:  600;
  
}
.input-box .icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}
.wrapper .remember-forgot {
  display: flex;
  justify-content: space-between;
  font-size: 14.5px;
  /* margin: -15px 0 15px; */
}

.remember-forgot label input {
  accent-color: #fff;
  margin-right: 4px;
}

.remember-forgot a {
  color: #fff;
  text-decoration: none;
}
.remember-forgot a:hover {
  text-decoration: underline;
}

/* 11.42 */
.wrapper button {
  width: 92%;
  height: 56px;
  background: #262881;
  border: none;
  outline: none;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  margin: 16px;
}
.wrapper .register-link {
  font-size: 16px;
  color: #202224;
  text-align: center;
  opacity: 0.8;
  font-weight: 600!important;
  
  /* margin-top: 20px; */
}

.register-link p a {
  color: #5a8cff;
  text-decoration: none;
  font-weight: 600;
  
}

.register-link p a:hover {
  text-decoration: underline;
}

.register linkme {
  color: #000000;
}
.sub_para {
  color: #202224;
  font-family: Avenir Next LT Pro;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 21.6px;
  letter-spacing: -0.06428570300340652px;
  opacity: 0.7;
}
