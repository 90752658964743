.statusPaid{


background: #159D4F;
color: #ffffff;

}
.statusPending{
    background: #FDAB3D;
    color: #ffffff;
}
.statusUnpaid{
    background: #E2445C;

    color: #ffffff;
}
.light_heading{
    font-family: Avenir Next LT Pro;
font-size: 20px;
font-weight: 500;
line-height: 24px;
text-align: left;
color: #6E7079;

}
.red_head{
    font-family: Avenir Next LT Pro;
font-size: 20px;
font-weight: 500;
line-height: 24px;
text-align: left;
color: #E23852;
}
.green_head{
    font-family: Avenir Next LT Pro;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #159D4F;

}
