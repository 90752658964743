.calender_box {
  /* .p-inputtext {
        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: 1rem;
        color: #4b5563;
        background: #ffffff;
        padding: 0.75rem 0.75rem;
        border: 0.6px solid #979797 !;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px;
        outline-color: transparent;
    } */
  .calender_icon {
    position: absolute;
    right: 10px;
    top: 5px;
  }
  .custom-calendar input::placeholder {
    color: #202224;
  }
  .custom-calendar :hover {
    border-color: #0e1073 !important ;
  }
}
.accordion_section {
  .accordion-button:focus {
    box-shadow: none !important;
  }
  .icon_plus {
    background-color: #d4dffe;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    /* text-align: center; */
    color: #0e1073;
    padding: 0px 3px;
  }
  .icon_minus {
    background-color: #f7acac;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    text-align: center;
    color: #f15555;
    padding: 0px 3px;
  }
  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 15px;
    color: #6e7079 !important;
    text-align: left;
    background-color: transparent !important;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
  }
  .accordion {
    border-width: 0px !important;
    border: none !important;
  }
  .custom_accordion .accordion-item {
    border: none; /* Remove the border of accordion items */
  }
  .accordion-button:hover {
    background: transparent !important;
  }
  /* .accordion-button:not(.collapsed)::after {
        margin-left: 10px;
      }
     */
  .accordion_content {
    color: #6e7079;
    font-family: Avenir Next LT Pro;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
  }
}
