.user_section {
  & ul {
    padding-left: 0px !important;
  }
  .pages {
    font-size: 15px;
    color: #666666;
    font-weight: 500 !important;
  }
  .pages_dropdown {
    color: #8b8d97;
    width: 55px;
    height: 23px;
    /* padding: 4px 12px 4px 12px; */
    /* gap: 10px; */
    border-radius: 8px;
    /* opacity: 0px; */
  }
  .arrow_bck{
    color: #0e1073;
    background-color: #E7EDFF;
    border-radius: 8px;
    padding: 0px 5px;
  }
 
}
.labels {
  font-family: Avenir Next LT Pro;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #202224;
}
.labelsRequire {
  font-family: Avenir Next LT Pro;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #202224;
}
.labelsRequire::after {
  content: " *";
  color: red;
}
.user_profile_section {
  .light_heading {
    font-family: Avenir Next LT Pro;
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #6e7079;
  }
  .p-dropdown .p-dropdown-label.p-placeholder{
    color:  #6E7079 !important;
  }
}
.policy_section {
  .plus {
    color: #0e1073;
    text-align: center;
    width: 40px;
    height: 40px;
    /* padding: 2px; */
    border-radius: 50%;
    background-color: #d4dffe;
    font-size: 25px;
  }
  .minus {
    color: #f15555;
    text-align: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f7acac;
    font-size: 25px;
  }
  .user_setting {
    .p-dropdown {
      background-color: #0e1073 !important;

    }
    
    .p-dropdown .p-dropdown-trigger {
      /* background-color: #0e1073 !important; */
      color: #fff !important;
    }
    .p-dropdown .p-dropdown-label.p-placeholder {
      color: #fff !important;
    }
   
  }
  .p-dropdown .p-dropdown-label.p-placeholder{
    color:  #6E7079 !important;
  }
}

.recharge_section {
  .user_setting {
    .p-dropdown {
      background-color: #0e1073 !important;

    }
    
    .p-dropdown .p-dropdown-trigger {
      /* background-color: #0e1073 !important; */
      color: #fff !important;
    }
    .p-dropdown .p-dropdown-label.p-placeholder {
      color: #fff !important;
    }
   
  }
  .filter_dropdown {
    .p-dropdown .p-dropdown-trigger {
      color: #202224 !important;
    }
    .p-dropdown .p-dropdown-label.p-placeholder {
      color: #202224 !important;
    }
  }
  .option_dropdown {
    .p-dropdown .p-dropdown-trigger {
      color: #0e1073 !important;
    }
    .p-dropdown {
      background-color: transparent !important;
      border: none !important;

    }
    .p-dropdown .p-dropdown-label.p-placeholder {
      color: #0e1073 !important;
    }
  }

}

.account_info{
  /* .p-dialog .p-dialog-content{
    padding: 1rem 3rem !important;
    border: 1px solid red !important;
  } */
  .light_heading{
    font-family: Avenir Next LT Pro;
font-size: 18px;
font-weight: 400;
line-height: 23.4px;
letter-spacing: 0.012em;
text-align: left;
color: #6E7079;
  }
  .p-dropdown .p-dropdown-label.p-placeholder{
    color:  #6E7079 !important;
  }

}


