.NewLeads01_section{
    .labels {
        font-family: Avenir Next LT Pro;
        font-size: 16px;
        font-weight: 500 !important;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #202224;
      }
      .labelsRequire {
        font-family: Avenir Next LT Pro;
        font-size: 16px;
        font-weight: 500 !important;
        line-height: 24px;
        letter-spacing: 0.012em;
        text-align: left;
        color: #202224;
      }
      .labelsRequire::after {
        content: " *";
        color: red;
      }
     
        .light_heading {
          font-family: Avenir Next LT Pro;
          font-size: 22px;
          font-weight: 500;
          line-height: 32px;
          text-align: left;
          color: #6e7079;
        }
        .p-dropdown .p-dropdown-label.p-placeholder{
          color:  #6E7079 !important;
        }
     
}
.p-inputswitch:not(.p-disabled):has(.p-inputswitch-input:hover).p-highlight .p-inputswitch-slider {
    background: #D4DFFE;
}
.p-inputswitch .p-inputswitch-slider {
    background: #D4DFFE;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 30px;
    outline-color: transparent;
}
.p-inputswitch .p-inputswitch-slider:before {
    background: #0E1073;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.25rem;
    margin-top: -0.625rem;
    border-radius: 50%;
    transition-duration: 0.2s;
}
