.sidebar_con {
  height: 100vh;
  box-sizing: border-box;
  display: grid;

  transition: grid-template-columns 0.4s ease;

  a {
    color: #202224;
    text-decoration: none;
  }

  .logo_i {
    align-items: center;
    margin-left: 20px;
    margin-bottom: 12px;
  }
  .left_con {
    overflow: scroll;
    height: 100%;
    grid-template-columns: 240px 1fr;
    background: var(--Dark-Base-Blue, #ffffff);

    .logo_main_con {
      margin: 30px 0px 22px 25px;
      /* display: flex;
      justify-content: center; */
    }

    .selectTree2 {
      color: rgb(228, 199, 199) !important;

      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      text-transform: capitalize;
    }

    .p-placeholder,
    .selectTree2 .p-dropdown-trigger,
    .p-inputtext {
      color: var(--Text, #b89e9e);

      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      padding: 2px !important;
      border: none !important;
    }

    .p-dropdown-trigger {
      background-color: #2ac37d;
    }

    .navlink_con {
      /* padding: 0px 28px; */
      margin: 10px 10px;
      align-items: center;

      .navlink {
        display: flex;
        align-items: center;
        column-gap: 13px;
        color: var(--PT-40, #dd0000);
        font-size: 15px;
        border-radius: 6px;
        font-weight: 600 !important;
        text-transform: capitalize;

        margin-right: 20px;
        margin-left: 20px;
        padding: 11px 20px;

        text-align: center;
        cursor: pointer;
        outline: none;
        color: #202224;
        /* background-color: #0E1073; */
        border: none;

        /* box-shadow: 0 px #999; */
      }

      .navlinkk {
        color: #202224;
      }
      .button:hover {
        background-color: #3e8e41;
      }

      .button:active {
        background-color: #3e8e41;
        box-shadow: 0 5px #666;
        transform: translateY(4px);
      }
    }
  }
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  /* padding: 1.25rem;
    border:none;
    color: #000000;
    background: #f9fafb;
    font-weight: 500;
    border-radius: 6px;
    transition: box-shadow 0.2s; */
  display: flex;
  align-items: center;
  column-gap: 13px;
  font-size: 0.938rem;
  border-radius: 3px;
  font-weight: 500 !important;
  text-transform: capitalize;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 8px;
  padding: 11px 20px;
  text-wrap: nowrap;
  cursor: pointer;
  outline: none;
  color: #202224;
  /* background-color: #0E1073; */
  border: none;
}
.p-accordion .p-accordion-content {
  padding: 10px 0 10px 60px;
  flex-direction: column;
  gap: 14px;
  display: flex;
  border: none;
  font-size: 0.938rem;
  background: none;
  color: #202224;
  font-family: "Avenir Next LT Pro";
  font-weight: 400 !important;
}
.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background: none;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  background: none;
}

.right_con {
  box-sizing: border-box;
  /* position: fixed;
    top: 0;
    right: 0; */

  .con {
    display: flex;
    gap: 50px;
    align-items: center;
  }

  .top_barr {
    height: 80px;

    display: flex;

    padding: 19px 30px;
    align-items: center;
    text-align: end;
    gap: 70px;
    justify-content: end;
    padding: 0px 28px;
    justify-content: space-between;

    .nav_title {
      color: #000000;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }

    .language_select {
      border-radius: 12px;
      background: rgba(15, 155, 90, 0.15);
      margin-right: 20px;
    }

    .icon_con {
      display: flex;
      align-items: center;
      font-size: 1.875rem;
      column-gap: 20px;
    }
  }

  .children_con {
    padding: 24px 24px 24px 24px;
    background-color: rgb(241, 241, 241);

    .child_body {
      overflow: hidden;
      background-color: #ffffff;
      height: calc(100vh - 124px);
      box-sizing: border-box;
      border-radius: 25px;
    }

    .childOutlet {
      width: 100%;
      height: 100%;
      padding: 32px;
      overflow: auto;
    }
  }
}

.header-bar_1 {
  display: flex;
  gap: 16px;
  .user-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    color: #53545c;
  }
  .online-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 16px;
    color: #519c66;
  }
}

.header-bar_2 {
  display: flex;
  gap: 16px;
  align-items: center;
}
.admin_name {
  font-weight: 600;
  size: 14px;
  color: #404040;
  line-height: 27.8px;
}

.admin_position {
  font-weight: 500;
  size: 12;
  text-align: start;
  line-height: 14.4px;
  color: #565656;
}

.logo_ii {
  margin-top: 7px;
}
