.borderedTable_main_con {

    &
    th{
        /* border: 1px solid #ECEEF2 !important; */
        background-color: #F1F4F9 !important;
        color: #202224 !important;
        font-size: 15px !important;
        font-weight: 600 !important;
        font-family: 'Avenir Next LT Pro' !important;
    }

    &
    tbody{
        background-color: white;
        font-size: 15px;
        font-weight: 400;
        color: #353638 !important;
        font-family: 'Avenir Next LT Pro' !important;
    }
    
}
