.searchInput_main_con {
  position: relative;

  & input {
    width: 100%;
    height: 38px;
    border: 0.6px solid #979797 !important;
    border-radius: 12px;
    padding-left: 40px;
    width: 456px;
  }

  .search_icon {
    position: absolute;
    top: 8px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 10px;
  }
}

.searchInput_main_con input:focus {
  border-color: #0e1073;

  outline: none;

}

.dropDown {
  /* width: 133px; */
  height: 38px !important;

  border-radius: 12px 0px 0px 0px;
  border: 0.6px 0px 0px 0px;
  

  /* opacity: 0px; */
}

.upload {
  border-bottom: 1px solid lightgray;
  height: 38px;
  width: 100%;
}

.uploadIcon {
  position: absolute;
  top: 10px;
  right: 0;
}
