.Logs_section {
  .date_calender {
    .p-calendar .p-inputtext {
      flex: 1 1 auto;
      width: 155px !important;
    }
  }

}
.SmsLogs_section{
    .sms_calendar{
        .p-calendar .p-inputtext {
            flex: 1 1 auto;
            width: 155px !important;
          }
    
      }
}
