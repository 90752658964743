.flex {
  display: flex;
}
.center {
  align-items: center;
}
.between {
  justify-content: space-between;
}
.end {
  justify-content: end;
}

@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("/public/font/AvenirNextLTPro-Regular.otf");
}

body {
  font-family: "Avenir Next LT Pro" !important;
}
.card_conatiner {
  background-color: #ffffff;
  border-radius: 12px;
}

h1 {
  font-family: Avenir Next LT Pro;
  font-size: 28px !important;
  font-weight: 600 !important;
  line-height: 40px;
  color: #202224 !important;
  margin-bottom: 0px !important;
}
h2 {
  font-family: Avenir Next LT Pro;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 24px;
  color: #202224;
  margin-bottom: 0px !important;
}
h3 {
  font-family: Avenir Next LT Pro;
  font-size: 28px;
  font-weight: 600 !important;
  line-height: 40px;
  color: #202224;
  margin-bottom: 0px !important;
}
h4 {
  font-family: Avenir Next LT Pro;
  font-size: 28px;
  font-weight: 600 !important;
  line-height: 40px;
  color: #202224;
  margin-bottom: 0px !important;
}
h6 {
  font-family: Avenir Next LT Pro;
  font-size: 15px;
  font-weight: 600 !important;
  line-height: 40px;
  color: #6e7079 !important;
  margin-bottom: 0px !important;
}
p {
  margin-bottom: 0px !important;
  font-weight: 500 !important;
}
.fw-6 {
  font-weight: 600;
}
.pointer {
  cursor: pointer;
}

.btn_primary_btn {
  /* width: 149px;
height: 38px; */
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 6px;
  border: none;
  color: #fff !important;
  background: #0e1073 !important;
  font-weight: 500 !important;
  font-size: 15px;
}
.cancel_btn {
  background: none;
  color: #0e1073;
  border: 1px solid #0e1073;
  border-radius: 6px;
  padding: 10px 24px 10px 24px;
}
.unused {
  background: #d4dffe;
  color: #0e1073;
  border: none;
  border-radius: 8px;
  padding: 6px 20px;
}
.delete_btn {
  font-family: Avenir Next LT Pro;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: center;
  color: #f15555;
  border: 1px solid #f15555;
  background: transparent;
  border-radius: 8px;
  padding: 10px 24px;
}
.upload_btn {
  color: #0e1073;
  background: transparent;
  border: 1px solid #0e1073;
  border-radius: 10px;
  padding: 8px 16px;
}
.line {
  background-color: #b3b3b3;
  height: 20px;
  width: 1px;
  border: 1px;
}
.labels {
  font-family: Avenir Next LT Pro;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #202224;
}
.labelsRequire {
  font-family: Avenir Next LT Pro;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 24px;
  letter-spacing: 0.012em;
  text-align: left;
  color: #202224;
}
.labelsRequire::after {
  content: " *";
  color: red;
}
.export {
  font-family: Avenir Next LT Pro;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #6e7079;
  cursor: pointer;
}
.pages {
  font-size: 15px;
  color: #666666;
  font-weight: 500 !important;
}
.pages_dropdown {
  color: #8b8d97;
  width: 55px;
  /* height: 23px; */
  padding: 0px 6px;
  /* gap: 10px; */
  border-radius: 8px;
  /* opacity: 0px; */
}
.arrow_bck {
  color: #0e1073;
  background-color: #e7edff;
  border-radius: 8px;
  padding: 0px 5px;
  text-align: center;
}

.p-inputtext {
  padding: 6px 20px !important;
  height: 38px;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #fff9f9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dddddd;
}
ul {
  padding-left: 0px !important;
}
.p-dialog .p-dialog-header {
  font-family: Avenir Next LT Pro;
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  text-align: left;
  color: #000000 !important;
}
/* tabs css  */
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
}
.p-tabview-nav-link {
  color: #6e7079;
  text-decoration: none;
  font-family: Avenir Next LT Pro;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 23.4px;
  letter-spacing: 0.012em;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #e5e7eb00;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #e5e7eb00 transparent;
  background: #ffffff;
  color: #6b7280;
  /* padding: 1.25rem 4rem  1.25rem 0px; */
  font-weight: 700;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
  border-bottom: solid #e5e7eb !important;
  border-color: transparent transparent #e5e7eb transparent;
  color: #0e1073;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #0e1073;
  color: #000000 !important;
}
.p-tabview .p-tabview-nav {
  border: none;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  display: none;
}
.p-tabview .p-tabview-panels {
  padding: 0px;
  color: #202224;
}
.p-treeselect .p-treeselect-label {
  padding: 7px;
}
/* // */
.navlink_con .active {
  & div {
    background-color: #0e1073;
    color: #ffffff !important;
  }
}
.navlink_cn .active {
  & div {
    color: #0e1073 !important;
    background-color: #ffffff;
  }
}

.statusBg {
  padding: 6px 20px;
  border-radius: 8px;
  background: rgba(255, 0, 0, 0.13);
}
.statusActive {
  background: #32936f29;
  color: #519C66;
}
.statusExpire {
  background: #f36d6d9d;
  color: red;
}
.dotActive {
  background-color: #519C66;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.dotExpire {
  background-color: #C5C5C5;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.dark_content {
  color: #202224 !important;
  font-weight: 600 !important;
  font-size: 15px;
  opacity: 0.9;
}
.light_content {
  color: #6e7079 !important;
  font-weight: 600 !important;
  font-size: 22px;
}
.theme_color {
  color: #0e1073;
  font-size: 500 !important;
}
/* radio button */
.p-radiobutton.p-highlight .p-radiobutton-box {
  background: #0e1073 !important;
  border-color: #0e1073 !important;
}
.p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
  border-color: #0e1073;
}
.p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:focus-visible) .p-radiobutton-box {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  /* box-shadow: 0 0 0 0.2rem #a5f3fc; */
  /* border-color: #06b6d4; */
}
/* // */

/* checkbox css */
.p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible)
  .p-checkbox-box {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #7373ff;
  border-color: #0e1073;
}
.p-checkbox.p-highlight .p-checkbox-box {
  border-color: #0e1073;
  background: #0e1073;
}
.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
  border-color: #0e1073;
}
/* // */

/* placeholder dropdown */
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #202224;
}
/* // */

/* dropdown focus */
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  /* box-shadow: 0 0 0 0.2rem #7373ff; */
  box-shadow: none;
  border-color: #0e1073 !important;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #0e1073;
}
/* // */

/* drop down highlight color */
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #0e1073 !important;
  background: #e7edff;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background: #e7edff;
}
/* // */

/* table highlight */
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #e7edff !important;
  color: #0e1073;
}
/* // */
input[type="text"]:focus {
  border-color: #0e1073 !important; /* Change the border color to red when focused */
}
input[type="text"]:hover, input[type="password"]:hover {
  border-color: #0e1073 !important; /* Change the border color to red when hovered */
}

/* input border color */

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a5f3fc;
  border-color: #0e1073;
}
.p-inputtext:enabled:hover {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a5f3fc;
  border-color: #0e1073;
}
.p-inputtext:enabled:focus {
  /* box-shadow: 0 0 0 0.2rem #a5f3fc; */
  box-shadow: none;
}
.p-inputtext:enabled:hover {
  /* box-shadow: 0 0 0 0.2rem #a5f3fc; */
  box-shadow: none;
}
/* // */
/* .p-paginator .p-paginator-last {
  background-color: red;
  display: none;
}
.p-paginator .p-paginator-first{
  background-color: red;
  display: none;

} */